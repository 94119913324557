import moment from 'moment/moment';
import { ValueTypes } from '../constants';

export const ChartTypes = {
    BAR: 'BAR',
    LINE: 'LINE',
    PIE: 'PIE',
};

export const ChartViewEnums = {
    MONTHLY: 'MONTHLY',
    DAILY: 'DAILY',
};
export const ChartViewTypes = [
    { name: 'Monthly', id: ChartViewEnums.MONTHLY },
    { name: 'Daily', id: ChartViewEnums.DAILY },
];

export function getMonthsBetween(startDateStr, endDateStr) {
    const start = moment(startDateStr, 'YYYY-MM-DD');
    const end = moment(endDateStr, 'YYYY-MM-DD');
    const months = [];
    let isFirst = true;

    while (start.isSameOrBefore(end, 'month')) {
        if (isFirst) {
            months.push(start.format('YYYY-MM-DD'));
            isFirst = false;
        } else {
            months.push(start.startOf('month').format('YYYY-MM-DD'));
        }
        start.add(1, 'month');
    }
    return months;
}

export function getDaysBetween(startDateStr, endDateStr) {
    const start = moment(startDateStr, 'YYYY-MM-DD');
    const end = moment(endDateStr, 'YYYY-MM-DD');
    const days = [];

    while (start.isSameOrBefore(end, 'day')) {
        days.push(start.format('YYYY-MM-DD'));
        start.add(1, 'day');
    }
    return days;
}

export function groupAndSumByYearAndMonth(data) {
    if (data) {
        const grouped = data?.reduce((acc, item) => {
            const date = new Date(item.date);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');

            const key = `${year}-${month}`;

            if (!acc[key]) {
                acc[key] = { count: 0, date: `${year}-${month}-01` };
            }

            acc[key].count += item.count;

            return acc;
        }, {});

        return Object.values(grouped);
    }
}


export function groupAndSumByYearMonthAndDay(data) {
    if (data) {
        const grouped = data?.reduce((acc, item) => {
            const date = new Date(item?.date);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');

            const key = `${year}-${month}-${day}`; // Include the day in the key

            if (!acc[key]) {
                acc[key] = { count: 0, date: `${year}-${month}-${day}` };
            }

            acc[key].count += item.count;

            return acc;
        }, {});

        return Object?.values(grouped);
    }
}

export function mapCountsToDateRange(dateRangeList, groupedCurrent) {
    const groupedMap = groupedCurrent?.reduce((acc, item) => {
        acc[item.date] = item.count;
        return acc;
    }, {});

    return dateRangeList.map((date) => groupedMap[date] || null);
}

const mergeDates = (currentDates, prevDates) => {
    const currentYear = new Date(currentDates[0]).getFullYear();

    const updatedPrevDates = prevDates.map(date => {
        const dateObj = new Date(date);
        dateObj.setFullYear(currentYear); // Update the year
        return dateObj.toISOString().split('T')[0];
    });

    const mergedDates = [...new Set([...currentDates, ...updatedPrevDates])];

    return mergedDates.sort((a, b) => new Date(a) - new Date(b));
};
const compareDates = (mergedDates, prevPeriod) => {
    const prevMapped = prevPeriod.map(item => {
        const date = new Date(item.date);

        return {
            count: item.count,
            monthDay: `${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,
        };
    });

    return mergedDates.map(date => {
        const currentDate = new Date(date);
        const monthDay = `${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;

        // Find if current date matches any date in prevPeriod by month and day
        const match = prevMapped.find(item => item.monthDay === monthDay);
        return match ? match.count : null;
    });
};


// export function BarChartData(info, currentPeriod, prevPeriod, view) {
//     if (info?.startDate && info?.endDate && currentPeriod && prevPeriod) {
//         // Monthly Dates
//         const monthsBetween = getMonthsBetween(info?.startDate, info?.endDate);
//         const monthDates = monthsBetween?.map((date) => moment(date).format('MMM YY'));
//
//         // Current data
//         const groupedCurrent = groupAndSumByYearAndMonth(currentPeriod);
//         const monthCurrent = mapCountsToDateRange(monthsBetween, groupedCurrent);
//
//         // Previous data
//         const prevMonthsBetween = getMonthsBetween(info?.prevStartDate, info?.prevEndDate);
//         const groupedPrev = groupAndSumByYearAndMonth(prevPeriod);
//         const monthPrev = mapCountsToDateRange(prevMonthsBetween, groupedPrev);
//
//
//         // Daily data
//         const currentDailyDates = groupAndSumByYearMonthAndDay(currentPeriod)?.map((item) => item.date);
//         const prevDailyDates = groupAndSumByYearMonthAndDay(prevPeriod)?.map((item) => item.date);
//         const mergedDates = mergeDates(currentDailyDates, prevDailyDates);
//
//
//         const dailyCurrent = groupAndSumByYearMonthAndDay(currentPeriod)?.map((item) => item.count || null);
//
//         const prevDaily = compareDates(mergedDates, groupAndSumByYearMonthAndDay(prevPeriod));
//
//         const dates = view === ChartViewEnums.MONTHLY ? monthDates : mergedDates;
//         const current = view === ChartViewEnums.MONTHLY ? monthCurrent : dailyCurrent;
//         const prev = view === ChartViewEnums.MONTHLY ? monthPrev : prevDaily;
//
//         return {
//             dates, current, prev,
//         };
//     } else {
//         return {
//             dates: [], current: [], prev: [],
//         };
//     }
// }
export function BarChartData(info, currentPeriod, prevPeriod, view) {
    if (info?.startDate && info?.endDate && currentPeriod && prevPeriod) {
        // Monthly Dates
        const monthsBetween = getMonthsBetween(info?.startDate, info?.endDate);
        const daysBetween = getDaysBetween(info?.startDate, info?.endDate);
        const monthDates = monthsBetween?.map((date) => moment(date).format('MMM YY'));

        // Current data
        const groupedCurrent = groupAndSumByYearAndMonth(currentPeriod);
        const monthCurrent = mapCountsToDateRange(monthsBetween, groupedCurrent);

        // Previous data
        // const prevMonthsBetween = getMonthsBetween(info?.prevStartDate, info?.prevEndDate);
        // const groupedPrev = groupAndSumByYearAndMonth(prevPeriod);
        // const monthPrev = mapCountsToDateRange(prevMonthsBetween, groupedPrev);


        // Daily data
        const currentDailyDates = currentPeriod?.length ? groupAndSumByYearMonthAndDay(currentPeriod)?.map((item) => item?.date) : daysBetween;
        const prevDailyDates = groupAndSumByYearMonthAndDay(prevPeriod)?.map((item) => item.date);
        const mergedDates = mergeDates(currentDailyDates, prevDailyDates);

        const dailyCurrent = groupAndSumByYearMonthAndDay(currentPeriod)?.map((item) => item.count || null);

        // const prevDaily = compareDates(mergedDates, groupAndSumByYearMonthAndDay(prevPeriod));

        const dates = view === ChartViewEnums.MONTHLY ? monthDates : mergedDates;
        const current = view === ChartViewEnums.MONTHLY ? monthCurrent : dailyCurrent?.length ? dailyCurrent : [null];
        // const prev = view === ChartViewEnums.MONTHLY ? monthPrev : prevDaily;

        return {
            dates, current,
        };
    } else {
        return {
            dates: [], current: [],
            // prev: [],
        };
    }
}


const getValueForDate = (date, data) => {
    const item = data.find(d => d.date === date);
    return item ? item.count : 0;
};

const getValueMonthForDate = (date, data) => {
    const [targetYear, targetMonth] = date.split('-').slice(0, 2); // Extract year and month from the date
    const filteredItems = data.filter(d => {
        const [year, month] = d.date.split('-').slice(0, 2);
        return month === targetMonth;
    });

    return filteredItems?.reduce((sum, item) => sum + item.count, 0);
};


const mergeLineChartDates = (currentDates, prevDates) => {
    if (currentDates?.length) {
        const currentYear = new Date(currentDates[0].date).getFullYear();

        const updatedPrevDates = prevDates.map(item => ({
            count: item.count,
            date: item.date.replace(/^\d{4}/, currentYear),
        }));

        // const mergedDates = [...currentDates, ...updatedPrevDates];
        const mergedDates = [...currentDates, ...prevDates];

        const startDate = new Date(`${currentYear}-01-01`);
        const endDate = new Date(`${currentYear}-12-31`);
        const allDates = [];
        while (startDate <= endDate) {
            allDates.push(new Date(startDate));
            startDate.setDate(startDate.getDate() + 1);
        }

        const result = allDates.map(date => {
            const dateString = date.toISOString().split('T')[0];
            const current = mergedDates.find(item => item.date === dateString && item.count ? item.count : 0) || { count: 0 };
            const previous = updatedPrevDates.find(item => item.date === dateString && item.count ? item.count : 0) || { count: 0 };

            return {
                date,
                Current: current.count ? current.count : 0,
                Previous: previous.count ? previous.count : 0,
            };
        });

        return result.filter(entry => entry.Current !== 0 || entry.Previous !== 0);
    }
};


// export function LineChartData(info, currentPeriod, prevPeriod, view) {
//     if (info?.startDate && info?.endDate && currentPeriod && prevPeriod) {
//         // Dates
//         const monthsBetween = getMonthsBetween(info?.startDate, info?.endDate);
//
//         // Current data
//         const groupedCurrent = groupAndSumByYearAndMonth(currentPeriod);
//         const groupedPrev = groupAndSumByYearAndMonth(prevPeriod);
//
//         const newArray = monthsBetween?.map(date => {
//             const current = getValueForDate(date, groupedCurrent);
//             const previous = getValueMonthForDate(date, groupedPrev);
//
//             const newDate = new Date(date);
//             newDate.setDate(newDate.getDate() + 2);
//
//             return { date: newDate, Current: current, Previous: previous };
//         });
//
//
//         const currentDailyDates = groupAndSumByYearMonthAndDay(currentPeriod);
//         const prevDailyDates = groupAndSumByYearMonthAndDay(prevPeriod);
//         const mergedDates = mergeLineChartDates(currentDailyDates, prevDailyDates);
//
//         const lineChartDates = view === ChartViewEnums.MONTHLY ? newArray : mergedDates || []
//         return { lineChartDates };
//     } else {
//         return {
//             lineChartDates: [],
//         };
//     }
// }
export function LineChartData(info, currentPeriod, view) {
    if (info?.startDate && info?.endDate && currentPeriod) {
        // Dates
        const monthsBetween = getMonthsBetween(info?.startDate, info?.endDate);
        const daysBetween = getDaysBetween(info?.startDate, info?.endDate);

        // Current data
        const groupedCurrent = groupAndSumByYearAndMonth(currentPeriod);
        // const groupedPrev = groupAndSumByYearAndMonth(prevPeriod);

        const newArray = monthsBetween?.map(date => {
            const current = getValueForDate(date, groupedCurrent);
            // const previous = getValueMonthForDate(date, groupedPrev);
            const newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 2);
            return { date: newDate, Current: current };
        });

        const currentDailyDates = currentPeriod?.length ? groupAndSumByYearMonthAndDay(currentPeriod) : daysBetween?.map((date) => ({
            count: 0,
            date: date,
        }));

        const mergedDates = currentPeriod?.length ? mergeLineChartDates(currentDailyDates, [])
            :
            currentDailyDates?.map((item) => ({ date: new Date(item.date), Current: 0 }));

        const lineChartDates = view === ChartViewEnums.MONTHLY ? newArray : mergedDates || [];
        return { lineChartDates };
    } else {
        return {
            lineChartDates: [],
        };
    }
}


const renderColor = (item) => {
    switch (item) {
        case 'Pending':
            return '#A6F4C5';
        case 'Completed':
            return 'rgb(42, 142, 109)';
        case 'Cancelled':
            return '#344054';
        case 'Active':
            return '#00C851';
        case 'Inactive':
            return '#E9EAEB';
        case 'Hold':
            return '#B9E6FE';
        case 'Terminated':
            return '#FECDCA';
        case 'On Hold':
            return '#FEDF89';
        case 'FBA':
            return '#ADD8E6';
        case 'Wait List':
            return '#FE7B43';
        case 'Graduated':
            return '#D9D6FE';
        case 'Appealed':
            return '#FDDCAB';

        default:
            return null;
    }
};



export function PieChartData(chartType, info) {
    if (chartType === ChartTypes.PIE && info) {
        const pieChartData = info ? info?.map((item, k) => (
            renderColor(item?.label) ?
                {
                    label: item?.label,
                    value: item?.value,
                    color: renderColor(item?.label),
                }
                :
                {
                    label: item?.label,
                    value: item?.value,
                    color: colors[k],
                }
        )) : [];
        return { pieChartData };
    } else {
        return [];
    }
}

export const renderChartValueFormat = (valueType) => {
    switch (valueType) {
        case ValueTypes.NUMBER:
            return ' ';
        case ValueTypes.DOLLAR:
            return '$';
        case ValueTypes.PERCENT:
            return '%';
        default:
            return ' ';
    }
};

export const toDollar = (amount, decimals = 0) => {
    if (isNaN(amount)) return '$0';
    return `$${Number(amount).toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};


export function ValueFormatter(value, activeChart) {
    if (activeChart?.valueFormat === ValueTypes.DOLLAR) {
        return toDollar(value);
    } else {
        return `${renderChartValueFormat(activeChart?.valueFormat)}${value}`;
    }
}


const colors = [
    "#FF6384", "#36A2EB", "#FFCE56", "#4CAF50", "#FF9800", "#9C27B0", "#00BCD4", "#E91E63", "#8BC34A", "#795548",
    "#607D8B", "#673AB7", "#3F51B5", "#F44336", "#CDDC39", "#FFC107", "#2196F3", "#00E676", "#FF5722", "#9E9E9E",
    "#B71C1C", "#880E4F", "#4A148C", "#311B92", "#1A237E", "#0D47A1", "#01579B", "#006064", "#004D40", "#1B5E20",
    "#33691E", "#827717", "#F57F17", "#FF6F00", "#E65100", "#BF360C", "#3E2723", "#212121", "#263238", "#D50000",
    "#C51162", "#AA00FF", "#6200EA", "#304FFE", "#2962FF", "#0091EA", "#00B8D4", "#00BFA5", "#00C853", "#64DD17",
    "#AEEA00", "#FFD600", "#FFAB00", "#FF6D00", "#DD2C00", "#8D6E63", "#90A4AE", "#EF9A9A", "#F48FB1", "#CE93D8",
    "#B39DDB", "#9FA8DA", "#90CAF9", "#81D4FA", "#80DEEA", "#80CBC4", "#A5D6A7", "#C5E1A5", "#E6EE9C", "#FFF59D",
    "#FFE082", "#FFCC80", "#FFAB91", "#B0BEC5", "#EF5350", "#EC407A", "#AB47BC", "#7E57C2", "#5C6BC0", "#42A5F5",
    "#29B6F6", "#26C6DA", "#26A69A", "#66BB6A", "#9CCC65", "#D4E157", "#FFEE58", "#FFCA28", "#FFA726", "#FF7043",
    "#8D6E63", "#BDBDBD", "#78909C", "#FF5252", "#FF4081", "#E040FB", "#7C4DFF", "#536DFE", "#448AFF", "#40C4FF",
    "#18FFFF", "#64FFDA", "#69F0AE", "#B2FF59", "#EEFF41", "#FFFF00", "#FFD740", "#FFAB40", "#FF6E40", "#37474F"
];
import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { inputsStyle } from './styles';
import { ErrorText, FindLoad, Images, useGlobalStyles } from 'utils';
import { ErrMessage } from '../messages';
import { InputMinLoader } from './inputMiniLoader';
import { TextRow } from '../table';
import { SelectEnums } from './constants';
import { MinLoader } from '../loader';


export const SelectTypeAutocomplete = ({
                                           list,
                                           title,
                                           defaultValue,
                                           name,
                                           handleSelect,
                                           style,
                                           disabled,
                                           error,
                                           typeError,
                                           handleType,
                                           loadType,
                                           placeholder,
                                           renderValue,
                                           customClass,
                                           uiType, outLabel, noError, outStyle, renderStatus,
                                           startIcon, CustomPaperComponent,
                                           defaultSelected,
                                           refresh,
                                       }) => {
    const classes = inputsStyle();
    const globalStyles = useGlobalStyles();
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const loader = FindLoad(loadType);
    const refreshClicked = useRef(false);
    // const filteredList =  value ? list?.length && list?.filter((i) => i.id !== value.id) : list?.length ? list : [];
    const optionList = refresh ? (list?.length ? [...list, { id: SelectEnums.REFRESH }] : [{ id: SelectEnums.REFRESH }]) : list;

    // const defaultProps = {
    //     options: value ? list?.length && list?.filter((i) => i.id !== value.id) : list?.length ? list : [],
    //     getOptionLabel: (option) => renderValue(option) || '',
    // };

    const handleClick = (e, ev) => {
        setValue(ev);
        if (ev) {
            let info = { target: { value: ev.id, name: name } };
            handleSelect(info);
            setValue(ev.id);
        } else {
            let info = { target: { value: '', name: name } };
            handleSelect(info);
            setValue('');
        }
    };

    useEffect(() => {
        if (!defaultSelected) {
            if (defaultValue && list?.length) {
                list?.find((i) => {
                    if (i.id === defaultValue || i._id === defaultValue) {
                        setValue(i);
                    }
                });
            } else {
                setValue(null);
            }
        }

        if (defaultSelected && defaultValue) {
            setValue(defaultSelected);
        } else if (defaultSelected && !defaultValue) {
            setValue(null);
        }
    }, [defaultValue, list, defaultSelected]);


    return (
        <div className={style ? style : ''}>
            {outLabel && <p className={classes.labelTitle} style={{ ...outStyle }}>{outLabel}</p>}
            <Autocomplete
                id="disable-close-on-select"
                name={name}
                loading={!!loader?.length}
                style={{ width: '100%' }}
                className={
                    uiType ? classes.noChipLoads :
                        disabled ? classes.inputTextFieldDisabled :
                            customClass ? customClass :
                                error === name ? globalStyles.inputTextFieldError :
                                    defaultValue ? globalStyles.inputTextFieldBlue :
                                        classes.inputTextField
                }
                getOptionLabel={(option) => renderValue(option) || ''}
                options={optionList}
                key={optionList}
                value={value}
                inputValue={inputValue}
                disabled={disabled}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={(event, reason) => {
                    if (refreshClicked.current) {
                        event.preventDefault();
                        refreshClicked.current = false;
                        return;
                    }
                    setOpen(false);
                }}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue === SelectEnums.REFRESH) {
                        return '';
                    } else {
                        setInputValue(newInputValue);
                    }
                }}
                onChange={(event, newValue) => {
                    if (newValue?.id === SelectEnums.REFRESH) {
                        refreshClicked.current = true;
                        refresh();
                        setOpen(true);
                    } else {
                        handleClick(event, newValue);
                        setOpen(false);
                    }
                }}
                renderOption={(option) => {
                    const lastElement = option?.id === list[list.length - 1]?.id;
                    return (
                        option?.id === SelectEnums.REFRESH ?
                            <div className="refresh-btn-wrapper">
                                <button
                                    onClick={refresh}
                                    className="refresh-btn"
                                >
                                    <div className="refresh-btn-image-box">
                                        {loader?.length ?
                                            <MinLoader
                                                height={16}
                                                width={17}
                                                color={'#0C66E4'}
                                                margin={'0'}
                                                position={'relative'}
                                            />
                                            :
                                            <img src={Images.refreshSelect} alt="refresh" />
                                        }
                                    </div>
                                    <p>Refresh</p>
                                </button>
                            </div>
                            :
                            <div className="full-width">
                                <div style={{ width: '100%', gap: '4px' }} className={'selector-wrapper'}>
                                    {option?.img && <img src={option?.img} alt={'icon'} />}
                                    {renderStatus && <div
                                        className={option?.status === 'ACTIVE' ? 'circle-box green' : 'circle-box red'} />}
                                    <TextRow name={renderValue(option) ? renderValue(option) : ' '} textWidth={7} />
                                </div>
                                {lastElement && CustomPaperComponent &&
                                    <div className="full-width">
                                        {CustomPaperComponent}
                                    </div>
                                }
                            </div>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        style={{ color: 'red' }}
                        {...params}
                        InputProps={loader?.length || startIcon ? {
                                ...params.InputProps,
                                endAdornment: !!loader?.length && (
                                    <InputMinLoader />
                                ),
                                startAdornment: startIcon && <img src={startIcon} alt="icon" />,
                            } :

                            { ...params.InputProps }
                        }
                        placeholder={placeholder}
                        label={title}
                        variant="outlined"
                        onChange={(e) => handleType && handleType(e)}
                    />
                )}
            />
            {!noError &&
                <ErrMessage
                    text={error === name ? typeError ? typeError : ErrorText.field : ''}
                    style={{ position: 'relative' }}
                />
            }
        </div>
    );
};